import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'HealNowMD', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Get your Maryland Medical Marijuana Card Now', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Get Your Maryland Medical Cannabis Card',
  body: 'Fast, Professional And Convenient Service',
  subbody1: 'Free Consultations',
  subbody2: 'Lowest Price In Maryland',
  cta: 'Get In Touch Now',
  img: 'weedmaps_logo.svg',
  featured: 'Featured On',
  reviews: 'With 5 Star Reviews ⭐️⭐️⭐️⭐️⭐️',
  proceeds: '15% of all proceeds donated to local Maryland charities and shelters',
  weedmapsHref: 'https://weedmaps.com/doctors/dr-vitaly-stepensky',
  callMobileHref: 'tel:+17178848259',
  mailToHref:
    "mailto:healnow@dr.com?subject=Medical Marijuana License&body=Hi I'm interested in getting a Medical Marijuana Card",
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'I am a Maryland licensed physician with 8 years of experience in general medicine and 4 years in medical cannabis. Easy and fast communication is my goal. Please call / text me and we can identify a consultation time convenient for you and to prevent extended wait times in the office. If I do not answer, leave a message and I will follow-up within 30-60 minutes.',
  emailSubject: 'Medical Marijuana License',
  emailBody: "Hi I'm interested in getting a Medical Marijuana Card",
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: '',
    },
    {
      id: uuidv1(),
      name: 'codepen',
      url: '',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: '',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
